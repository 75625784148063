@import '~antd/es/style/themes/default.less';

.headline {
  margin-top: 24px;
}
.logoContainer {
  margin-top: 24px;
}

@media (max-width: @screen-md-min) {
  .headline {
    text-align: center;
  }
  .logoContainer {
    text-align: center;
  }
}

@media (min-width: @screen-lg-min) {
  .headline {
    float: left;
  }
  .logoContainer {
    text-align: right;
    float: right;
  }
}